<template>
  <div>
    <v-dialog v-model="dialog"
              persistent
              scrollable
              width="500"
    >

      <v-card :disabled="btnLoading"
              :loading="btnLoading">

        <v-card-title>
          {{ titleDialog }}
          <v-spacer/>
          <v-icon @click="close">mdi-close</v-icon>
        </v-card-title>

        <v-divider/>

        <v-card-text class="pa-8">

          <v-text-field outlined
                        label="Nom *"
                        dense
                        :error-messages="errors.name"
                        v-model="form.name"></v-text-field>

          <v-text-field outlined
                        label="Montant *"
                        dense
                        type="number"
                        :error-messages="errors.amount"
                        v-model="form.amount"></v-text-field>


          <v-select v-model="form.service_id"
                    :error-messages="errors.service_id"
                    :items="services"
                    item-text="name"
                    :loading="serviceLoading"
                    item-value="id"
                    outlined
                    dense
                    label="Service *"
          />

          <v-select v-model="form.flexy_offer_type_id"
                    :error-messages="errors.flexy_offer_type_id"
                    :items="types"
                    :loading="typeLoading"
                    item-text="name"
                    outlined
                    dense
                    item-value="id"
                    label="Type *"
          />

          <v-file-input ref="file"
                        outlined
                        dense
                        :error-messages="errors.image"
                        accept="image/png, image/jpeg, image/jpg, image/svg, .svg"
                        chips
                        label="Image *"
                        show-size
                        @change="uploadFile"
                        @focus="errors && errors.image ? (errors.image = '') : false"
          />

        </v-card-text>


        <v-divider/>

        <v-card-actions>
          <v-spacer/>
          <v-btn :loading="btnLoading"
                 color="primary"
                 depressed
                 type="submit"
                 @click="save">
            <v-icon left>mdi-content-save</v-icon>
            Enregistrer
          </v-btn>
        </v-card-actions>

      </v-card>


    </v-dialog>

  </div>
</template>

<script>

import {HTTP} from "@/http-common";

export default {
  data() {
    return {
      titleDialog: "",
      dialog: false,
      btnLoading: false,
      serviceLoading: false,
      typeLoading: false,
      form: {},
      errors: {},
      services: [],
      types: [],
    }
  },
  methods: {
    uploadFile(file) {
      this.form.image = file;
    },
    open(item) {
      this.getServices()
      this.getTypes()
      this.titleDialog = item ? "Modifier un offre" : "Ajouter un offre"
      this.dialog = true
      this.errors = {}
      this.form = {
        id: item ? item.id : null,
        name: item ? item.name : null,
        amount: item ? item.amount : null,
        flexy_offer_type_id: item ? item.flexy_offer_type_id : null,
        service_id: item ? item.service_id : null,
        is_active: item ? item.is_active : true,
        image: null,
      }

      if (this.$refs.file) {
        this.$refs.file.reset();
      }

    },
    close() {
      this.dialog = false
    },
    save() {
      this.errors = {};
      let url = this.form.id ? '/v1/flexy-offers/update' : "/v1/flexy-offers/store";
      let formData = new FormData();

      formData.append('id', this.form.id);
      formData.append('name', this.form.name);
      formData.append('amount', this.form.amount);
      formData.append('flexy_offer_type_id', this.form.flexy_offer_type_id);
      formData.append('service_id', this.form.service_id);
      formData.append('is_active', this.form.is_active ? 1 : 0);

      if (this.form.image) {
        formData.append('image', this.form.image);
      }

      this.btnLoading = true;

      HTTP.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(() => {
        this.btnLoading = false;
        this.$successMessage = "Cette offre a été enregistrée avec succès.";
        this.close();
        this.$emit('refresh');
      }).catch(err => {
        this.btnLoading = false;
        this.errors = err.response.data.errors;
        this.$errorMessage = "Une erreur s'est produite veuillez corriger.";
        console.log(err);
      });
    },
    getServices() {
      this.serviceLoading = true
      HTTP.get("/service?size=1000", {params: {}}).then((res) => {
        this.services = res.data.data;
        this.serviceLoading = false
      }).catch((err) => {
        console.log(err);
        this.serviceLoading = false
      });
    },
    getTypes() {
      this.typeLoading = true
      HTTP.get("/v1/flexy-offers/types")
          .then((res) => {
            this.typeLoading = false
            this.types = res.data.data;
          })
          .catch((err) => {
            this.typeLoading = false
            console.log(err);
          });
    },
  }
}
</script>

